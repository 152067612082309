<div class="sliders">
    <div class="slider">
        <em class="icon icon-battery"></em>
        <span class="title">{{ 'Energy storage' | translate }}</span>
        <app-slider
            id="energy-storage-slider"
            [value]="energyStorageSliderValue()"
            [recommended]="'Recommended size of energy storage: <strong>size kWh</strong>' | translate: {size: recommendedEnergyStorageSize | number: '1.1-1'}"
            (updateValue)="updateEnergyStorageSlider($event)"
        />
        <span class="consumption"><span>{{ energyStorageConsumption | number: '1.2-2' }}</span> kWh/{{ 'year' | translate }}</span>
    </div>
</div>

<hr>

@if (batterySelect2Data().length > 0) {
    <div class="battery-top-container" [class.open]="batteryEnabled()">
        <div class="battery">
            <div class="battery-image">
                <img ngSrc="/assets/battery.webp" width="206" height="280" alt="Battery">
            </div>
            <div class="battery-container">
                <div class="battery-headline">
                    <div>
                        <div class="battery-title">{{ 'Add Battery' | translate }}</div>
                        <div class="battery-sub-title">{{ 'Add' | translate }} {{ battery()?.data }}</div>
                    </div>
                    <div>
                        <app-toggle [isReadonly]="isReadonly()" [active]="batteryEnabled()" (toggleEvent)="toggleSection($event)" />
                    </div>
                </div>
                <div class="battery-headline">
                    <div>
                        <div class="battery-title">{{ 'Number of batteries' | translate }}</div>
                        <div class="battery-sub-title" [innerHTML]="'Recommended size of energy storage: <strong>size kWh</strong>' | translate: {size: recommendedEnergyStorageSize | number: '1.1-1'}"></div>
                    </div>
                    <div>
                        <app-count-input [isReadonly]="isReadonly()" [value]="batteryCount()" (changeCount)="changeBatteryCount($event)" />
                    </div>
                </div>
                @if (!isReadonly()) {
                    <div class="battery-headline">
                        <div>
                            <div class="battery-title">{{ 'Battery type' | translate }}</div>
                        </div>
                        <div>
                            <select2
                                id="battery"
                                required
                                [value]="batteryValue()"
                                [data]="batterySelect2Data()"
                                (update)="batteryUpdate($event)"
                            />
                        </div>
                    </div>
                }
            </div>
        </div>

        <div class="backup-guide">
            <p class="guide-title">{{ 'Why system with batteries?' | translate }}</p>
            <ul class="check-list">
                <li>{{ 'Solar energy at night' | translate }}</li>
                <li>{{ 'More savings' | translate }}</li>
                <li>{{ 'Faster return on investment' | translate }}</li>
            </ul>
        </div>
    </div>
}
