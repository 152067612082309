@if (imageError()) {
    <app-image-placeholder />
} @else {
    <div class="concept-image">
        <canvas #canvasElementRef [width]="canvasWidth" [height]="canvasHeight"></canvas>
    </div>
}

<div style="display: none">
    @if (image(); as image) {
        <img priority [ngSrc]="image" (load)="onImageLoaded($event)" (error)="this.imageError.set(true)" [width]="imageWidth" [height]="imageHeight" alt="Concept">
    }
</div>
