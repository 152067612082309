export enum LanguageCode {
    English = 'en-us',
    Polish = 'pl',
    Spanish = 'es',
    Portuguese = 'pt-br',
    Swedish = 'sv',
    Ukrainian = 'uk',
    Norwegian = 'nb',
    Dutch = 'nl',
    German = 'de',
    Bulgarian = 'bg-bg',
    Slovak = 'sk',
    Latvian = 'lv',
    Catalan = 'ca',
    French = 'fr',
    Croatian = 'hr',
    Greek = 'el',
    Turkish = 'tr',
    Czech = 'cs',
    Danish = 'da',
    Estonian = 'et',
    Finnish = 'fi',
    Hungarian = 'hu',
    Italian = 'it',
    Lithuanian = 'lt',
    Romanian = 'ro',
}

export const languages: string[] = [
    LanguageCode.English,
    LanguageCode.Polish,
    LanguageCode.Spanish,
    LanguageCode.Portuguese,
    LanguageCode.Swedish,
    LanguageCode.Ukrainian,
    LanguageCode.Norwegian,
    LanguageCode.Dutch,
    LanguageCode.German,
    LanguageCode.Bulgarian,
    LanguageCode.Slovak,
    LanguageCode.Latvian,
    LanguageCode.Catalan,
    LanguageCode.French,
    LanguageCode.Croatian,
    LanguageCode.Greek,
    LanguageCode.Turkish,
    LanguageCode.Czech,
    LanguageCode.Danish,
    LanguageCode.Estonian,
    LanguageCode.Finnish,
    LanguageCode.Hungarian,
    LanguageCode.Italian,
    LanguageCode.Lithuanian,
    LanguageCode.Romanian,
];
