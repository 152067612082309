import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TenantResponse } from '../domain/api-result';
import { LanguageCode, languages } from '../domain/languages';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly translateService: TranslateService =
        inject(TranslateService);

    public setLanguageOptions(company: TenantResponse | undefined): void {
        let preferredLang: string = '';

        if (undefined !== company) {
            preferredLang = company.language_code;
        }

        this.translateService.addLangs(languages);
        this.translateService.setDefaultLang(LanguageCode.English);

        if (languages.includes(preferredLang)) {
            this.translateService.use(preferredLang);
            return;
        }

        for (const curLang of navigator.languages) {
            if (languages.includes(curLang.toLowerCase())) {
                this.translateService.use(curLang.toLowerCase());
                return;
            }
        }
    }
}
